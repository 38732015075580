import React, { FunctionComponent, MouseEventHandler } from "react";
import styles from "./initialsCircle.module.css";

type PropsType = {
  initials: string;
  color?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  style?: boolean;
  hoverStyle?: "red" | "green" | "none"; // New property for hover styling
};

const InitialsCircle: FunctionComponent<PropsType> = ({
  initials,
  color = "#fff",
  onClick,
  style,
  hoverStyle = "none", // Default to "none"
}) => {
  return (
    <div
      className={`${style ? styles.initials__square : styles.initials} ${
        hoverStyle === "red"
          ? styles.hoverRed
          : hoverStyle === "green"
          ? styles.hoverGreen
          : ""
      }`}
      onClick={onClick}
    >
      <div className={styles.initails__typography}>{initials}</div>
    </div>
  );
};

export default InitialsCircle;
